import "../styles/globals.css";
import "regenerator-runtime/runtime.js"; // needed to get the link repo working

import type { AppProps } from "next/app";
import { appWithTranslation } from "next-i18next";

function MyApp({ Component, pageProps, router }: AppProps) {
  return <Component {...pageProps} key={router.route} />;
}

export default appWithTranslation(MyApp);
