import * as Sentry from "@sentry/nextjs";

const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN;
const DEV = process.env.NODE_ENV === "development";

if (!DEV) {
  Sentry.init({
    environment: "production",
    dsn: SENTRY_DSN,
    tracesSampleRate: 1.0, // business decision: this value will most likely need to be updated
  });
}
